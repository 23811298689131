import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";

import { FOOTER_HEIGHT } from "./constants";

const StyledFooter = styled.div`
  bottom: 0;
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;
  background-color: #2d3030;
  color: #76d1dd;
  height: ${FOOTER_HEIGHT};
`;

const StyledUL = styled.ul`
  list-style: none;
  padding-inline-start: 20px;
  @media (min-width: 901px) {
    padding-inline-start: 40px;
  };

  li {
    display: inline;
    margin: 0 0 0 10px;
    @media (min-width: 901px) {
      margin: 0 0 0 10px;
  };

    :first-child {
      margin: 0;
    }

    ::after {
      content: "|";
      margin-left: 10px;
    }

    :last-child {
      ::after {
        content: "";
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <StyledUL>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <a
            href="https://t.me/JuniorDevsBot"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join us on Telegram
          </a>
        </li>
        <li>
          <a
            href="https://github.com/Junior-Devs/2020-Junior-Devs-Project"
            target="_blank"
            rel="noopener noreferrer"
          >
            Our Project&rsquo;s GitHub Repository
          </a>
        </li>
        <li>
          <a href="https://mailchi.mp/abb520dc76fb/junior-devs"
            target="_blank"
            rel="noopener noreferrer">
            Subscribe to our newsletter
          </a>
        </li>
      </StyledUL>
    </StyledFooter>
  );
};

export default Footer;
