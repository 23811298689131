import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";

const LearnJavaScript = () => {
  return (
    <ContentWrapper>
      <section>
        <h1>5 great free resources to learn JavaScript in 2020</h1>
        <p>Do you want to learn JavaScript in 2020? We’ve put together a list of resources to help you out, and the best is they’re all free! </p>

        <h2><a href="https://www.freecodecamp.org/" target="_blank" rel="noopener noreferrer">freeCodeCamp</a></h2>
        <p>We at Junior Devs are big fans of freecodecamp. There are several modules in the curriculum dedicated to JavaScript frameworks and libraries, but if you’re looking to learn the foundation of JavaScript, we recommend the JavaScript Algorithms and Data Structures Certification.</p>

        <h2><a href="https://www.coursera.org/learn/duke-programming-web" target="_blank" rel="noopener noreferrer">Programming Foundations with JavaScript, HTML and CSS at Coursera</a></h2>
        <p>This is a great course to start looking into problem solving with JavaScript. To get a certificate you have to pay, but you can audit the classes for free.</p>

        <h2><a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank" rel="noopener noreferrer">MDN JavaScript Docs</a></h2>
        <p>An excellent resource not only for a quick reference, but also for in-depth explanations and guides.</p>

        <h2><a href="https://www.udemy.com/" target="_blank" rel="noopener noreferrer">Udemy</a></h2>
        <p>Although many Udemy courses are paid, you can find a bunch of free ones <a href="https://www.udemy.com/topic/javascript/free/"  target="_blank" rel="noopener noreferrer">here</a>. If you are more of a visual learning and like to follow tutorials, Udemy is a great choice. If you don&lsquo;t mind paying, they run sales regularly. </p>

        <h2><a href="https://github.com/getify/You-Dont-Know-JS" target="_blank" rel="noopener noreferrer">You Don’t Know JS</a></h2>
        <p>A great book that explores JavaScript in depth. It will give you the solid foundations you will need, from the very basics to advanced topics.</p>

        <p>With these resources, you should be set to start on your learning journey. Have fun and don’t forget to share your progress in the Junior Devs Telegram group!</p>

      </section>
    </ContentWrapper>
  );
};

export default LearnJavaScript;
