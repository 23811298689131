import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";

const JobAdvice = () => {
  return (
    <ContentWrapper>
      <section>
        <h1>How to get your first job as a self-taught developer</h1>
        <p className="author-date">
          by{" "}
          <a
            href="https://www.virginiabalseiro.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Virginia Balseiro
          </a>{" "}
          - 30 January 2020
        </p>
        <p>
          About six months ago, I started a new position as a software engineer,
          my first full-time position ever. It was the culmination of two
          intense years during which I learned a lot and experienced what
          it&lsquo;s like to achieve your goals, but also experiencing rejection
          and failure along the way.
        </p>
        <p>
          I decided to write about my journey changing careers from teaching to
          development for two reasons: the first is that I get asked about it
          almost everyday on social media, and the second is that mine was a
          rather non-straightforward path. Mine was not the &ldquo;completed a
          bootcamp in 12 weeks and got my first job in tech&ldquo;, so I think
          it might serve as encouragement if you&lsquo;re also struggling to get
          your first opportunity.
        </p>
        <h2>1. Do your homework</h2>
        <p>
          A very important thing when setting goals for yourself is knowing
          exactly what you want. If you stretch yourself too wide, you might
          lose focus.
        </p>
        <p>
          An example of this is programming languages: while it&lsquo;s good to
          know more than one language, if your priority is getting a job
          quickly, the most important thing is to focus on the programming
          principles that go beyond any particular language, while at the same
          time learning marketable skills that will make you employable as soon
          as possible. Some of you might be able to afford taking time off work
          and just focus on learning, but for those of you who can&lsquo;t, you
          want to start working on real life stuff as soon as possible.
        </p>
        <p>
          In order to do this, ask yourself questions like: Where would you like
          to be in 1-2 years? Which companies do you find interesting? Where
          would you like to live and work? Based on this, research the market.
          Look at job postings for those companies you listed, or in the country
          you want to live, or if your dream is working remotely, look at remote
          jobs that look interesting to you and list down all the skills they
          ask for. Then put that through the filter of your own preferences and
          you will have a pretty good list to guide you through your learning
          journey.
        </p>
        <h2>2. Experience (or lack thereof)</h2>
        <p>
          One of the biggest issues faced by new developers is lack of
          experience. Companies want you to have 1-2 years of experience, but if
          no one wants to hire you, how can you get that experience?
        </p>
        <p>
          My advice is to start gaining any sort of experience as soon as
          possible while you are still learning. While I was doing the
          freeCodeCamp curriculum, I came across{" "}
          <a
            href="https://1millionwomentotech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            1millionwomentotech
          </a>
          , an online bootcamp aiming to bring tech education to women and
          non-binary people. I was interested in taking the courses, and they
          were looking for volunteers, so I signed up both as a student and as a
          volunteer.It was an incredible learning experience, and not only I got
          training out of it, but I also had my first real-world experience of
          working at a remote organization, I made friends, and got an excellent
          reference letter at the end of my volunteering.
        </p>
        <p>
          If you have the possibility to intern, this is also an excellent way
          of getting work experience as soon as possible. Not everyone has the
          privilege of being able to do an unpaid internship, but there are many
          paid internships out there. I was an{" "}
          <a
            href="https://www.outreachy.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Outreachy
          </a>{" "}
          intern and it was one of the most amazing experiences of my life.
          Outreachy is a program that organizes paid internships with free and
          open-source projects for people who are underrepresented in the tech
          industry. I loved it so much that I am now an Outreachy mentor for{" "}
          <a
            href="https://librehealth.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LibreHealth!
          </a>
        </p>
        <p>
          Other options to get hands-on experience are building your own
          project, alone or with a buddy, contributing to Open Source projects
          (you can help fixing bugs or writing or translating documentation), or
          freelancing. You can start by building websites for your friends and
          family&lsquo;s businesses. It will help you become confident with your
          skills and also add to your portfolio.
        </p>
        <p>
          It&lsquo;s important to start doing this while you&lsquo;re learning
          because the best way to really cement your knowledge is to actually
          use it in real-world scenarios.
        </p>
        <h2>3. Start interviewing as soon as possible</h2>
        Interviews are learning experiences. You can find out what you want and
        don&lsquo;t want in a company just by how an interview goes. Also, the
        more experience you have interviewing, the less nervous you get.
        <p>
          I started applying for jobs before I felt 100% ready, because I knew
          that I would never feel 100% ready. Apply even if you don&lsquo;t meet
          all the requirements, and get ready for your interview. If
          you&lsquo;re nervous about being underqualified, don&lsquo;t be: let
          them decide. If you made it to the interview, then they are definitely
          interested in you. Take notes of what they ask, and prepare questions
          for the interviewer as well. This is your opportunity to learn what
          companies are looking for, and how you can prepare yourself to provide
          that to them. Also, keep in mind that the interview goes both ways:
          they&lsquo;re evaluating you, but you should be evaluating them as
          well. It&lsquo;s a two-way street, and knowing this should help you
          feel more confident and less stressed during the interview itself.
        </p>
        <h2>
          4. Talk to as many people as you can who have accomplished what you
          want
        </h2>
        <p>
          If you know someone who got a job after learning on their own, ask
          them questions! Be specific. Vague questions are less likely to give
          you valuable information since every person&lsquo;s journey is
          different, but if you&lsquo;re specific you can get valuable insight.
          For instance, when people ask me &ldquo;how did you go from teacher to
          developer?&ldquo;, I find it&lsquo;s a very difficult question to
          answer, and the answer is kind of useless. It&lsquo;s just my personal
          story, and so many aspects of it are extremely unique to my personal
          circumstances. But if someone asks, &ldquo;how did you fill your
          knowledge gaps with X skill?&ldquo; I can give them more useful
          information, point them to resources, give them examples that they can
          put into practice, and so on.
        </p>
        <h2>5. Don&lsquo;t underestimate the skills you DO have</h2>
        <p>
          Particularly if you&lsquo;re changing careers, you might be a junior
          at programming, but you do have lots of valuable experience and
          transferable skills. If you have worked at all in your life, you have
          technical and non-technical knowledge that can be applied to many
          situations at your new job.
        </p>
        <p>
          Many junior developers are career changers who had jobs before, were
          probably good at their jobs, and have life experience that sets them
          apart from other candidates. They are also usually motivated enough
          that they took a big chance and learned a whole new set of skills.
          This shows great personal strength and it makes you an excellent
          candidate and a great asset for any company. Your previous experience,
          even if unrelated, is valuable! You just have to find a way to
          repurpose it and use it to your advantage in your new career path.
        </p>
        <h2>
          6. Be ready to fail, get up, dust yourself off and try again, MANY
          times
        </h2>
        <p>
          If you don&lsquo;t fail at things, you will never succeed at things.
          Learning to do anything in life means getting it wrong many times
          before finally getting it right. Don&lsquo;t worry about rejections:
          learn from them, take note and move on. Remember that persistence is
          your best friend, and you will eventually get the job if you just keep
          at it long enough.
        </p>
        <h2>Conclusion</h2>
        <p>
          I think that pretty much whatever you want to do in life is
          accomplishable. The level of difficulty might vary depending on your
          starting point, but the more you put yourself out there and talk to
          people, the more insight and connections you gain, which will help you
          in your journey.
        </p>
      </section>
    </ContentWrapper>
  );
};

export default JobAdvice;
