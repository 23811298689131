import React, { useState } from "react";

import "./GitGuide.css";

import Button from "@material-ui/core/Button";
import styled from "styled-components";
import ContentWrapper from "../../../Layout/ContentWrapper";
import ImgFirstTime from "./assets/first-time.png";
import ImgAfterFirstTime from "./assets/after-first-time.png";
import ImgForkButton from "./assets/fork-button.png";
import ImgRepositories from "./assets/repositories-in-menu.png";
import ImgCloneOrDownload from "./assets/clone-or-download-button.png";
import ImgCloneLink from "./assets/clone-link.png";
import ImgCloneOnTerminal from "./assets/clone-on-terminal.png";
import ImgStatusOnTerminal from "./assets/git-status.png";
import ImgAddFile from "./assets/git-add-app.png";
import ImgStatusAfter from "./assets/git-status-after.png";
import ImgCommit from "./assets/commit-on-terminal.png";
import ImgPush from "./assets/git-push.png";
import ImgGoToOriginalRepo from "./assets/go-to-original-repo.png";
import ImgCreatePR from "./assets/create-pull-request.png";
import ImgCompareAcrossForks from "./assets/compare-across-forks.png";
import ImgTwoRepos from "./assets/two-repos.png";
import ImgChooseYourRepo from "./assets/choose-your-repo.png";
import ImgCreatePullR from "./assets/create-p-r.png";
import ImgCreatedPR from "./assets/createdpr.png";
import ImgGitRemoteV from "./assets/git-remote-v.png";
import ImgConfigureUpstream from "./assets/configure-upstream.png";
import ImgCheckAddedRemote from "./assets/check-added-remote.png";
import ImgFetchUpstream from "./assets/fetch-upstream.png";
import ImgMergeUpstream from "./assets/merge-u-master.png";
import ImgVimMerge from "./assets/vim-message.png";

const ToggleButton = styled(Button)`
  && {
    color: white;
    border: none;
    background-color: #76d1dd;
    border-radius: 12px;
    font-size: 0.8em;
    margin: 15px 0;
    :hover {
      background-color: rgb(109, 193, 204);
    }
  }
`;

const StyledFigure = styled.figure`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
`;

const GitGuide = () => {
  const [firstTime, setFirstTime] = useState(true);

  return (
    <ContentWrapper>
      <section>
        <h1>Collaborating: A simple guide to Git and GitHub.</h1>
        <p className="author-date">
          by{" "}
          <a
            href="mailto:enzoparodi90@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Enzo Parodi
          </a>{" "}
          - 5 February 2020
        </p>
        <p>
          Welcome to our guide on how to use git to contribute to open source
          projects. Here you will learn step by step how to get a project
          running in your computer, as well as sharing the changes you make to
          it, and keeping it updated with the approved changes of other
          contributors.
        </p>
        <p>
          We know that at first git might seem hard, and it is surely quite
          complicated because of the many things that can be done with it, but
          it is a powerful and valuable tool that is a fundamental part in the
          career of a developer. This is a brief guide that we encourage you to
          complement with other material and experimenting.{" "}
        </p>
        <p>
          First of all, let's examine the main concepts of git and the meaning
          of some words that you will often come across in the git world.
        </p>
        <h2 className="internal-link-target">What is Git?</h2>
        <p>
          Git is a 'Version Control System'. It is a system that allows for
          multiple people to share and edit a project in a coordinated way, so
          that the chances of breaking the code and losing count of changes gets
          minimized. An important feature of git is that it allows you to send
          and retrieve changes from a project hosted remotely.
        </p>
        <h2 className="internal-link-target">What is GitHub?</h2>
        <p>
          GitHub is a 'Git repository hosting service'. It is a service where
          you can store your project (the repository), making it possible for
          other users to make a copy of it on their own GitHub account (that is
          called <b>forking</b>), or downloading the repository itself to have a
          local copy of it (that is called <b>cloning</b>). Forking is the
          approach commonly used in <b>Open Source</b> projects (projects with
          publicly available code, which can be redistributed and modified),
          which also involves cloning, since you will clone the forked
          repository to your local machine. This is the approach I will be
          explaining here.
        </p>
        <h2 className="internal-link-target">
          Steps to fork and then clone the repository
        </h2>
        <p>The steps we will be taking are the following:</p>
        <ul>
          <li>
            <a href="#create-github-account">Create a GitHub account</a>
          </li>
          <li>
            <a href="#install-git">Install Git on your computer</a>
          </li>
          <li>
            <a href="#validate-ssh">Validate your computer user to GitHub</a>
          </li>
          <li>
            <a href="#fork-repo">Forking a repository</a>
          </li>
          <li>
            <a href="#clone-repo">Clone the forked repository</a>
          </li>
          <li>
            <a href="#commit-changes">
              Commit the changes done in your computer
            </a>
          </li>
          <li>
            <a href="#push-changes">
              Push your changes back to the fork on your GitHub account
            </a>
          </li>
          <li>
            <a href="#create-pr">
              Create a Pull Request to apply your changes to the original
              repository
            </a>
          </li>
          <li>
            <a href="#fetch-and-merge">
              {" "}
              Keeping in sync with other contributors changes to the project
            </a>
          </li>
        </ul>
        <div className="center-content">
          <ToggleButton
            className="btn"
            onClick={() => setFirstTime(!firstTime)}
          >
            TOGGLE DIAGRAM
          </ToggleButton>
        </div>

        <StyledFigure id="git-diagram">
          {firstTime ? (
            <React.Fragment>
              <figcaption>
                Setting up your git repository{" "}
                <b>(use button to toggle the diagram)</b>
                <br />
              </figcaption>
              <img src={ImgFirstTime} alt="git forking diagram" width="100%" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <figcaption>
                Git flow after setting up the repository{" "}
                <b>(use button to toggle the diagram)</b>
                <br />
              </figcaption>
              <img
                src={ImgAfterFirstTime}
                alt="git forking diagram"
                width="100%"
              />
            </React.Fragment>
          )}
        </StyledFigure>
        <h2 className="internal-link-target" id="create-github-account">
          Create a GitHub account
        </h2>
        <p>
          If you don't yet have a GitHub account, start by creating one.
          Personal accounts are free, and you can create one{" "}
          <a
            href="https://www.github.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .{" "}
        </p>
        <h2 className="internal-link-target" id="install-git">
          Install Git on your computer
        </h2>
        <p>
          The next thing you need is to have Git installed and configured in
          your computer so that you can use it to clone the repository, add your
          changes and push them. Because this involves many steps on itself,
          follow{" "}
          <a
            href="https://git-scm.com/book/en/v2/Getting-Started-The-Command-Line"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            this link
          </a>{" "}
          and do steps 1.4, 1.5 and 1.6.
        </p>
        <h2 className="internal-link-target" id="validate-ssh">
          Validate your computer user to GitHub
        </h2>
        <p>
          Once you are done with that, follow{" "}
          <a
            href="https://help.github.com/en/enterprise/2.17/user/github/authenticating-to-github/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent#generating-a-new-ssh-key"
            target="_blank"
            rel="noopener noreferrer"
          >
            this link
          </a>{" "}
          to create and SSH key. An SSH key is basically an authentication
          method to tell GitHub that you are the valid user to use the account
          from your computer.
        </p>
        <p>
          So now you have a GitHub account and it is connected to your computer,
          from which you will be sending and receiving code to it using git. Now
          that you are set up, let's go ahead and fork that project to your
          GitHub account!
        </p>
        <h2 className="internal-link-target" id="fork-repo">
          Forking a repository
        </h2>
        <p>
          Go to the project you want to fork and on the top right corner, click
          on 'Fork' and click on your account name on the modal that opens up:
          <img src={ImgForkButton} alt="fork button in repo" width="100%" />
          Now you have a forked copy of the repository in your account. This
          copy is yours and yours only, and the changes you make there won't
          affect the original repository from which you forked until later when
          you make a pull request (a request to review and accept your changes
          to be integrated to that repository). We will get to that later. For
          now, let's see how you can clone your fork to use it in your own
          computer with your IDE of choice.
        </p>
        <h2 className="internal-link-target" id="clone-repo">
          Clone the forked repository
        </h2>
        <p>
          This step is valid for forks as well as for repositories you create
          yourself. Go to your GitHub account and find the fork you just created
          under 'Repositories':{" "}
          <img src={ImgRepositories} alt="repositories in menu" width="100%" />
          Open it, and then find the green button that says 'clone or download':
          <img
            src={ImgCloneOrDownload}
            alt="clone or download button in github"
            width="100%"
          />
          Click on it, and then on the file with an arrow icon next to the link:
          <br />
          <img src={ImgCloneLink} alt="copy link to clone" width="100%" />
          That copies the link to your clipboard. Now go to your command line
          where you use git, and inside the file where you want to put the
          project type <code>git clone </code> and paste the link, and THEN
          press enter:
          <img
            src={ImgCloneOnTerminal}
            alt="typing git clone on the terminal"
            width="100%"
          />
          Now a new folder with the project will be created on your machine.
          Congratulations! You now have a local copy of the repository! Now you
          can edit the files and then commit and push them
        </p>
        <h2 className="internal-link-target" id="commit-changes">
          Commit the changes done in your computer
        </h2>
        <p>
          Once you have made changes you are satisfied with, you have to commit
          them. A commit is a summary of changes with a descriptive name
          (provided by you). The idea is to commit as often as possible, one
          change at a time (often a change involves more than one file), and
          with a brief description (the commit message). To do this, you first
          have to add the changed file/s to the 'staging area', the intermediate
          area between the repository and your working area (see the{" "}
          <a href="#git-diagram">git diagram</a>). First look at all the changed
          files by typing in the command line <code>git status</code>:
          <img
            src={ImgStatusOnTerminal}
            alt="typing git status on the terminal"
            width="100%"
          />
          After that, type <code>git add </code> and the path of the folder you
          want to add (you can copy it exactly from what you see in git status)
          to your next commit, or <code>git add .</code> to add all the changed
          files: <br />
          <img
            src={ImgAddFile}
            alt="typing git add on the terminal"
            width="100%"
          />
          After doing that, you could type <code>git status</code> again, to see
          if your file was correctly added to ther staging area:
          <img
            src={ImgStatusAfter}
            alt="checking status after adding files"
            width="100%"
          />
          To commit the files, type "git commit -m 'commit message'", where '-m'
          means 'message', and the commit message is the description of the
          commit:
          <img src={ImgCommit} alt="commit changes" width="100%" />
          For guidance about writing good commit messages, click{" "}
          <a
            href="https://dev.to/puritanic/how-are-you-writing-a-commit-message-1ih7"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            here
          </a>
          . You can make as many commits as you need, and when you have changes
          that you consider sufficient, you can push them to your forked
          repository on github.{" "}
        </p>
        <h2 className="internal-link-target" id="push-changes">
          Push your changes back to the fork on your GitHub account
        </h2>
        <p>
          All you need to do now is type on the command line{" "}
          <code>git push</code> and git will push all your changes.
          <img src={ImgPush} alt="push commit to github" width="100%" />
        </p>
        <h2 className="internal-link-target" id="create-pr">
          Create a Pull Request to apply your changes to the original repository
        </h2>
        <p>
          Pull Requests are requests that you make to the owner of the original
          repository or anyone with{" "}
          <a href="https://help.github.com/en/github/getting-started-with-github/access-permissions-on-github">
            write access
          </a>
          , to merge your fork (now with your changes) into it. To create a Pull
          request, go to your GitHub repositories, look for the fork, and you
          will see right under its name, 'forked from ' with the name of the
          original owner, as a link to the original repository. Click on it:
          <img
            src={ImgGoToOriginalRepo}
            alt="location of link for original repo"
            width="100%"
          />
          Now you are on the original repo. click on 'New pull request':{" "}
          <img
            src={ImgCreatePR}
            alt="create pull request button"
            width="100%"
          />
          Finally, click on the link that says 'compare across forks':
          <img
            src={ImgCompareAcrossForks}
            alt="compare across forks link"
            width="100%"
          />
          Now you should see 4 dropdowns, two for the user/repository, and two
          for the branch of each. We haven't mentioned branching in this
          article, but it is not necesary right know. Just make sure that both
          repositories are on 'master'.
          <img
            src={ImgTwoRepos}
            alt="dropdowns with repos and branches"
            width="100%"
          />
          By default, both repositories will be the one in which you are, on the
          branch on which you are. Comparing a repository to itself on the same
          branch doesn't make sense, and that is why a little bit below you can
          see a message saying 'There isn’t anything to compare.'. Leave the
          repo on the left (base) as is, and click on the dropdown of the right
          one (head), to find your name/repository:
          <img
            src={ImgChooseYourRepo}
            alt="choose your repo from the dropdown"
            width="100%"
          />
          There should appear a rich text editor, where you should put a
          description as accurate and concise as possible of your changes, how
          you achieved them and, if you want, even some screenshots of the
          results. Now click on 'Create pull request':
          <img
            src={ImgCreatePullR}
            alt="create the pull request"
            width="100%"
          />
          If there are no conflicts (that happens when two files have changes in
          exactly the same line), you should see your pull request on the 'Pull
          requests' tab of the original repository:
          <img
            src={ImgCreatedPR}
            alt="view of the new pull request"
            width="100%"
          />
          Congratulations! You have now created your first pull resuest! What's
          left is to wait if the reviewer of your changes opens some issue/s,
          comments to make some modification, or merges your fork.
        </p>
        <h2 className="internal-link-target" id="fetch-and-merge">
          {" "}
          Keeping in sync with other contributors' changes to the project
        </h2>
        <p>
          Now that you have edited your copy of the original repository, made
          changes to it, and requested to apply them, you need to make sure you
          can pull the latest accepted changes from other contributors. So far,
          we have done everything in the first part of our{" "}
          <a href="#git-diagram">git diagram</a>. Now let's configure your local
          repo so that it pulls from the original repo and not from your own
          remote fork.
          <br />
          The first thing you will do, is see for yourself where your repository
          fetches changes from, and pushes changes to. Go to the command line
          and type <code>git remote -v</code>:
          <img
            src={ImgGitRemoteV}
            alt="see remote configuration on the terminal"
            width="100%"
          />
          As you can see, by default your local repo has only your own remote
          fork to fetch and push. We want to push to it, but we want to fetch
          from the original repository. Otherwise, you would be isolated from
          the changes happening there. We suggest you go back and take a good
          look at the <a href="#git-diagram">diagram</a> until you can clearly
          understand the flow. <br /> Good! Now let's set it up!
          <br />
          On your terminal, type: <br />
          <code>
            $ git remote add upstream
            https://github.com/ORIGINAL_OWNER/ORIGINAL_REPOSITORY.git
          </code>
          <br />
          replacing <code>ORIGINAL_OWNER</code> and{" "}
          <code>ORIGINAL_REPOSITORY</code> for your specific case, or simply
          copying the original repository's url like this:
          <img
            src={ImgConfigureUpstream}
            alt="configure upstream on the terminal"
            width="100%"
          />{" "}
          <br />
          Just to make sure the original repo got added as upstream, do{" "}
          <code>git remote -v</code> once again, and you should see it:
          <br />
          <img
            src={ImgCheckAddedRemote}
            alt="configure upstream on the terminal"
            width="100%"
          />{" "}
          Now you can fetch from the upstream repsitory, which means bringing
          those changes to a new local branch called upstream/master.
          <br />
          <img
            src={ImgFetchUpstream}
            alt="fetch upstream on the teminal"
            width="100%"
          />
          Now you need to merge upstream/master into your master branch:
          <img
            src={ImgMergeUpstream}
            alt="merge upstream on the teminal"
            width="100%"
          />
          It's likely that a text editor called Vim opens up asking you to write
          a commit message for the merge (a merge is actually a commit). In this
          case, just type ':q' down below, which means 'quit'. This will close
          the editor and get you back to the original terminal, while accepting
          the default merge message:
          <img
            src={ImgVimMerge}
            alt="merge upstream on the teminal"
            width="100%"
          />
          If something went wrong with Vim, you will learn with time that many
          people struggle with it, but we suggest you learn its basic use as
          soon as you can. With practice you will realize that it is not that
          scary a monster.
        </p>
        <p>
          So that was it! Do this last part often to make sure your code is
          never too far behind the central repository.
        </p>
        <h2>Final words</h2>
        <p>
          We hope this tutorial was helpful. We tried to make it as simple as
          possible but the truth is that there are many steps involved in this.
          The good news is that if you made it to this point, you will have
          acquired a good knowledge of the fundamentals of Git! If you have any
          doubts or comments don't hesitate to contact me by clicking on my name
          up on top.
        </p>
      </section>
    </ContentWrapper>
  );
};

export default GitGuide;
