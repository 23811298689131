import React from "react";
import styled from "styled-components";
import { Grid, Button, Container, Link } from "@material-ui/core";
import svgIllustration from "./images/undraw_friends_online_klj6.svg";

const BannerWrapper = styled(Container)`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 50px 0 50px 0;
  height: 60%;
  width: 100%;
  top: 50px;
  bottom: 50px;

  .close-button {
    color: white;
  }

  .banner-image {
    position: relative;
    width: 100%;
    max-height: 60vh;
    @media (min-width: 901px) {
      position: static;
      max-width: 70%;
      float: right;
    }
  }

  .telegram-banner-image {
    position: relative;
    max-height: 60vh;
    @media (min-width: 901px) {
      position: static;
      max-width: 70%;
      float: right;
    }
  }

  .btn {
    color: white;
    background-color: #76d1dd;
    border: none;
    border-radius: 12px;
    font-size: 1em;
    width: 120px;
    margin: 0 15px 15px 15px;
  }

  .btn:hover {
    color: #76d1dd;
  }

  .title {
    display: inline-block;
    margin: 0 0;
    font-size: 1.2em;
    font-weight: 200;
    text-align: center;

    @media (min-width: 601px) {
      font-size: 1.8em;
    }
  }
  .subtitle {
    display: inline-block;
    margin: 0px 0px 14px 0px;
    font-size: 0.8em;
    font-weight: 200;
    text-align: center;

    @media (min-width: 601px) {
      font-size: 1em;
    }
  }

  .text {
    display: inline-block;
    margin: 0px 0px 28px 0px;
    font-size: 0.8em;
    font-weight: 100;
    text-align: center;

    @media (min-width: 601px) {
      font-size: 1em;
    }
  }
`;

const OuterGrid = styled(Grid)`
  position: absolute;
  display: inline-block;
  align-self: center;
  justify-self: center;
  padding: 50px;
  z-index: 2;
  background: rgb(250, 250, 250, 0.9);
  text-align: center;
  flex-wrap: wrap;

  @media (min-width: 901px) {
    position: static;
    min-width: 30%;
  }
`;

const Banner = () => {
  return (
    <BannerWrapper>
      <OuterGrid container spacing={1}>
        <Grid item xs={12} container direction="column">
          <p className="title">Join us on Telegram!</p>
          <br />
          <p className="subtitle">
            Our group chat for beginner developers to support and help each
            other
          </p>
          <p className="text">
            Click below to start a chat with our bot and get your invite link
          </p>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" className="btn" fullWidth>
            <Link
              href="https://t.me/JuniorDevsBot"
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              color="inherit"
            >
              GET LINK
            </Link>
          </Button>
        </Grid>
      </OuterGrid>
      <img
        className="banner-image"
        src={svgIllustration}
        alt="Illustration of woman standing next to bubbles containing pictures of other people"
      />
    </BannerWrapper>
  );
};

export default Banner;
