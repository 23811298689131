import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px;
    margin: 0 0 50px 0;
    text-align: center;
    align-items: center;
`;

export default Wrapper;