import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@reach/router";
import styled from "styled-components";

import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import MenuIcon from "@material-ui/icons/Menu";

import NavButton from "./NavButton";
import NavBar from "./NavBar";

const useStyles = makeStyles({
  list: {
    width: 250,
    height: "100%",
    backgroundColor: "#76D1DD",
    borderLeft: "1px solid black"
  }
});

const MenuButton = styled(Button)`
  && {
    display: flex;
    min-width: 0;
    margin-left: auto;
    color: white;
    @media (min-width: 901px) {
      display: none;
    }
  }
`;

const NavButtonList = styled(List)`
  a {
    text-decoration: none;
    text-decoration: none;
    color: inherit;
    width: 100%;
    height: 100%;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
`;

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <NavButtonList>
        <NavButton onNavBar={"onNavBar"}>
            <a
              href="https://t.me/JuniorDevsBot"
              target="_blank"
              rel="noopener noreferrer"
            >
              JOIN US ON TELEGRAM
            </a>
          </NavButton>
        {[
          // { text: "MESSAGE BOARD", route: "/" },
          // { text: "JOBS", route: "/" },
          { text: "ABOUT", route: "/about" }
        ].map((buttonData, index) => {
          const { text, route } = buttonData;
          const hasBadge =
            text === "MESSAGE BOARD" || text === "JOBS" ? "withBadge" : null;
          return (
            <NavButton key={index} onNavBar={"onNavBar"} comingSoon={hasBadge}>
              <NavLink to={route}>{text} </NavLink>
            </NavButton>
          );
        })}
      </NavButtonList>
    </div>
  );

  return (
    <NavBar>
      <MenuButton onClick={toggleDrawer("right", true)}>
        <MenuIcon />
      </MenuButton>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        {sideList("right")}
      </Drawer>
    </NavBar>
  );
}
