import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";

const SubmissionGuide = () => {
  return (
    <ContentWrapper>
      <section>
        <h1>How to submit a blog post for Junior Devs</h1>
        <p>Thank you for considering writing for Junior Devs! Please read the following guidelines and when you’re ready, email us your blog post to <a href="mailto:juniordevsteam@gmail.com">juniordevsteam@gmail.com</a> in .doc, .docx, .txt, or .odf format. You can also just paste your post in the email body!</p>

        <h2>Guidelines</h2>
        <ol>
            <li><b>Know your audience:</b> this is a website aimed at beginning developers, so choose your topic accordingly and make sure it’s relevant. For example, an article about Blockchain will probably not be as relevant as one about how to get better at debugging or how to write a good resume.</li>

            <li><b>Keep the language beginner friendly:</b> if you use acronyms or highly technical terms, make sure to explain what they mean. Don’t assume your readers will know these, and take it as an opportunity to share that knowledge. </li>
    
            <li><b>Use inclusive language:</b> avoid using words that exclude groups of people, and/or gender specific words, for instance, instead of saying “guys”, try saying “folks”, “you”, “y’all”, “people”, or “friends”; instead of “mankind”, use “humanity” or “humankind”. You get the idea.</li>

            <li><b>Keep it international:</b> Junior Devs is composed of people from all over the world, so make sure you don’t excessively rely on cultural references or regionalisms, as a lot of people may not get them. </li>

            <li><b>Be patient:</b> we will review your submission as soon as possible, but sometimes we’re busy and might take a bit longer, so we appreciate your patience!</li>
        </ol>
      </section>
    </ContentWrapper>
  );
};

export default SubmissionGuide;
