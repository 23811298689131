import React from "react";
import { Link } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    margin: 15,
    borderRadius: 0
  },
  media: {
    height: 140
  }
});

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const GridCard = props => {
  const classes = useStyles();
  const { title, text, imgPath, route } = props;

  return (
    <Card className={classes.card}>
      <NavLink to={route}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={imgPath}
            title={title.replace(" ", "-")}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {text}
            </Typography>
          </CardContent>
        </CardActionArea>
      </NavLink>
      <CardActions>
        <Button size="small" color="primary">
          <NavLink to={route}>Learn more</NavLink>
        </Button>
      </CardActions>
    </Card>
  );
};

export default GridCard;
