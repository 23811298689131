import React from "react";
import { Grid } from "@material-ui/core/";
import GridCard from "./GridCard";
import styled from "styled-components";
import Img01 from "./images/undraw_version_control_9bpv.png";
import Img02 from "./images/undraw_google_docs_jf93.png";
import Img03 from "./images/undraw_reading_list_4boi.png";
import Img04 from "./images/undraw_organize_resume_utk5.png";
import Img05 from "./images/undraw_typewriter_i8xd.png";

const StyledGrid = styled(Grid)`
  flex-grow: 1;
`;

const items = [
  {
    title: "Read about our 2020 project!",
    text:
      "We're developing a project together in the new year. Read all about it and join us!",
    imgPath: Img02,
    route: "/project"
  },
  {
    title: "Git guide for our project",
    text: "New to git and GitHub? Here's how to contribute to our project!.",
    imgPath: Img01,
    route: "/git-guide"
  },
  {
    title: "Want to learn JavaScript in 2020?",
    text: "Here are the resources you need!",
    imgPath: Img03,
    route: "/learn-javascript"
  },
  {
    title: "Looking for your first programming job?",
    text: "Here are some tips to help you on your job hunt.",
    imgPath: Img04,
    route: "/job-advice"
  },
  {
    title: "Want to write for Junior Devs?",
    text: "Read our Submission Guidelines",
    imgPath: Img05,
    route: "/submission-guide"
  }
];

const GridList = () => {
  return (
    <StyledGrid container spacing={2}>
      <StyledGrid item xs={12}>
        <StyledGrid container justify="center">
          {items.map(item => (
            <GridCard
              className="grid-card"
              key={item.title.replace(" ", "-")}
              title={item.title}
              text={item.text}
              imgPath={item.imgPath}
              route={item.route}
            />
          ))}
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default GridList;
