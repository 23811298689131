import React, { Fragment } from "react";

import Banner from "./Banner";
import Wrapper from "./Wrapper";
import GridList from "./GridList/GridList";

const Home = () => {
  return (
    <Fragment>
      <Banner />
      <Wrapper>
        <GridList />
      </Wrapper>
    </Fragment>
  );
};

export default Home;
