import React from "react";
import { Link } from "@reach/router";
import { AppBar, Toolbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import NavButton from "./NavButton";
import {
  TITLE_FONT_SIZE_SMALL,
  TITLE_FONT_SIZE_BIG,
  HEADER_HEIGHT_SMALL,
  HEADER_HEIGHT_BIG
} from "../constants";

const StyledAppBar = withStyles({
  root: {
    backgroundColor: "#76D1DD"
  }
})(AppBar);

const StyledToolbar = withStyles({
  root: {
    minHeight: `${HEADER_HEIGHT_SMALL}rem`,
    ["@media (min-width: 901px)"]: {
      minHeight: `${HEADER_HEIGHT_BIG}rem`
    }
  }
})(Toolbar);

const TitleLink = styled(Link)`
  && {
    text-decoration: none;
    font-family: "Bungee", sans-serif;
    font-size: ${TITLE_FONT_SIZE_SMALL}em;
    line-height: 100%;
    margin-left: -5px;
    margin-block-start: 0;
    margin-block-end: 0;
    color: black;
    @media (min-width: 901px) {
      font-size: ${TITLE_FONT_SIZE_BIG}em;
      line-height: 100%;
      margin-left: 0;
    }
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
`;

const NavButtonList = styled.div`
  display: none;
  a {
    text-decoration: none;
    color: inherit;
  }
  @media (min-width: 901px) {
    display: block;
    margin-left: auto;
  }
`;

const NavBar = ({ children }) => {
  return (
    <StyledAppBar>
      <StyledToolbar>
        <TitleLink to="/">JUNIOR DEVS</TitleLink>
        {children}
        <NavButtonList>
          <NavButton>
            <a
              href="https://t.me/JuniorDevsBot"
              target="_blank"
              rel="noopener noreferrer"
            >
              JOIN US ON TELEGRAM
            </a>
          </NavButton>
          {/* <NavButton comingSoon={"withBadge"}>MESSAGE BOARD</NavButton>
          <NavButton comingSoon={"withBadge"}>JOBS</NavButton> */}
          <NavButton>
            <NavLink to="/about">ABOUT</NavLink>
          </NavButton>
        </NavButtonList>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default NavBar;
