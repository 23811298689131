import React from "react";
import ReactDOM from "react-dom";
import { Router } from "@reach/router";
import styled from "styled-components";
import { ScrollToTop } from './ScrollToTop';

import Navigation from "./Navigation";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Privacy from "./Pages/Privacy";
import Footer from "./Footer";
import JobAdvice from "./Pages/Articles/JobAdvice";
import LearnJavaScript from "./Pages/Articles/LearnJavaScript";
import OurProject from "./Pages/Articles/OurProject";
import GitGuide from "./Pages/Articles/git-guide/GitGuide";
import SubmissionGuide from "./Pages/Articles/SubmissionGuide";

import { HEADER_HEIGHT_SMALL, HEADER_HEIGHT_BIG } from "./constants";

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: ${HEADER_HEIGHT_SMALL}em;
  @media (min-width: 901px) {
    padding-top: ${HEADER_HEIGHT_BIG}em;
  }
`;

const App = () => {
  
  return (
    <PageContainer>
      <Navigation />
      <Router primary={false}>
        <ScrollToTop path="/">
          <Home path="/" />
          <About path="/about" />
          <Privacy path="/privacy" />
          <JobAdvice path="/job-advice" />
          <LearnJavaScript path="/learn-javascript" />
          <OurProject path="/project" />
          <GitGuide path="/git-guide" />
          <SubmissionGuide path="/submission-guide" />
        </ScrollToTop>
      </Router>
      <Footer />
    </PageContainer>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
