import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";

const OurProject = () => {
  return (
    <ContentWrapper>
      <section>
        <h1>
          The Junior Devs 2020 Project: an e-learning platform focused on
          education material to reduce waste
        </h1>
        <p>
          During a conversation in the Junior Devs Telegram group, we came up
          with the idea to collaborate on a project together as a way to learn
          valuable skills such as working remotely in a team, practicing our
          programming skills, possibly learning a new framework and having a
          nice project to add to our portfolios.{" "}
        </p>
        <p>
          All members of the group had a chance to add their ideas for a project
          to work on together on a shared doc, and we finally decided on one via
          a poll on the Telegram group.{" "}
        </p>
        <p>
          The most voted idea was proposed by{" "}
          <a
            href="https://twitter.com/j3zzika"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jessi Sanchez
          </a>
          . It’s an e-learning platform focused on educational material to
          reduce waste. We will be creating a website with a catalog of courses
          on different topics related to reducing waste, with the possibility to
          check each user’s progress on the different courses.{" "}
        </p>
        <p>Some of the suggested topics for the courses are: </p>
        <ul>
          <li>A journey to a &ldquo;Zero Waste&ldquo; Lifestyle</li>
          <li>Packaging Free Shopping</li>
          <li>Reduce plastic at home</li>
          <li>
            How to classify correctly your home waste:
            Paper/Glass/Plastic/Textil/Organic (for compost)/etc
          </li>
          <li>How to make compost at home</li>
        </ul>
        <p>
          Although we are not settled on the tech stack yet and we haven’t
          started planning the details, we will be using JavaScript to build our
          project, since most of the members of the group already know or are
          learning JavaScript.
        </p>
        <p>
          If you’d like to participate in the project,{" "}
          <a href="https://t.me/JuniorDevsBot">join our telegram group!</a> You
          can read more about it in the project repository{" "}
          <a href="https://github.com/Junior-Devs/2020-Junior-Devs-Project">
            here.
          </a>{" "}
          You can also{" "}
          <a
            href="https://mailchi.mp/abb520dc76fb/junior-devs"
            target="_blank"
            rel="noopener noreferrer"
          >
            subscribe to our newsletter
          </a>{" "}
          to receive news about the project.{" "}
        </p>
      </section>
    </ContentWrapper>
  );
};

export default OurProject;
