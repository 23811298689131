import React from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";

const StyledButton = styled(Button)`
  && {
    border: 2px solid white;
    color: white;
    border-radius: 12px;
    margin-left: 6px;
    padding-right: 15px;
    padding-left: 15px;

    &&.onNavBar {
      width: 95%;
      margin-bottom: 10px;
    }

    &&.withBadge {
      padding-right: 17px;
      padding-left: 17px;
      &::after {
        display: inline-block;
        content: "coming soon!";
        position: absolute;
        top: -8px;
        right: -5px;
        font-size: 9px;
        padding-left: 3px;
        padding-right: 3px;
        color: white;
        background-color: red;
        border-radius: 3px;
      }
    }
  }
`;

const NavButton = ({ children, comingSoon, onNavBar }) => {
  const classes = [];

  [comingSoon, onNavBar].map(prop => {
    if (prop) classes.push(prop);
  });

  return (
    <StyledButton className={classes} variant="outlined">
      {children}
    </StyledButton>
  );
};

export default NavButton;
