import React from "react";

import "../Layout/ContentWrapper";
import ContentWrapper from "../Layout/ContentWrapper";

const About = () => {
  return (
    <ContentWrapper>
      <section id="top">
        <ul>
          <li>
            <a href="#what-is-it">What is Junior Devs</a>
          </li>
          <li>
            <a href="#who-are-we">Who created Junior Devs?</a>
          </li>
          <li>
            <a href="#who-can-join">Who can join?</a>
          </li>
          <li>
            <a href="#guidelines">Telegram group guidelines</a>
          </li>
          <li>
            <a href="#code-of-conduct">Code of Conduct</a>
          </li>
          <li>
            <a href="#acknowledgements">Acknowledgments</a>
          </li>
        </ul>
      </section>
      <section className="internal-link-target" id="what-is-it">
        <h1>What is Junior Devs?</h1>
        <p>
          Junior Devs is a community for developers at the beginning of their
          careers to support and help each other. We have a Telegram group where
          we chat about different topics of interest to new beginner developers.
          We are also working on a project together! Read more about it{" "}
          <a href="/project">here</a>.
        </p>
      </section>
      <section className="internal-link-target" id="who-are-we">
        <h1>Who created Junior Devs?</h1>
        <p>
          Junior Devs was created by{" "}
          <a
            href="https://www.virginiabalseiro.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Virginia Balseiro
          </a>{" "}
          and{" "}
          <a
            href="https://www.enzoparodi.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Enzo Parodi
          </a>
          . We are both self-taught developers (we learned to code together!),
          we are married (to each other) and live in Germany with our cats and
          our dog Murray.
        </p>
      </section>
      <section className="internal-link-target" id="who-can-join">
        <h1>I&rsquo;m not a junior developer anymore. Can I join?</h1>
        <p>
          Sure! It&rsquo;s great for beginner developers to have someone more
          experienced to ask questions and review code. However, keep in mind
          that this is a beginners group, so stay on topic, be kind and mindful
          of the knowledge gaps, and try not to dominate the conversation. Also,
          read our Code of Conduct below.{" "}
        </p>
      </section>
      <section className="internal-link-target" id="guidelines">
        <h1>Telegram group guidelines</h1>
        <h2>What is the telegram group for?</h2>
        <p>
          This group is a community for junior developers. This is developers on
          their first role or their first year at the job. We of course welcome
          people who are still searching for their first job, and more
          experienced developers, but please stay on topic. This is not a group
          to learn how to code or to discuss very advanced topics. There are
          plenty of other places on the internet where you can do that.
        </p>
        <h2>What should be discussed in the chat and what should not?</h2>
        <p>
          Here are some examples of the chat should and should not be used for:
        </p>
        <b>Yes:</b>
        <ul>
          <li>Courses/books recommendations</li>
          <li>Recommendations on tools/frameworks etc.</li>
          <li>Tips on your job hunt, CV, etc.</li>
          <li>Advice on how to handle a situation at work</li>
          <li>Advice on how to level up on a particular topic</li>
        </ul>
        <b>No:</b>
        <ul>
          <li>
            Specific coding help (For this you can go to{" "}
            <a
              href="https://stackoverflow.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stack Overflow
            </a>
            , the{" "}
            <a
              href="https://www.freecodecamp.org/forum/"
              target="_blank"
              rel="noopener noreferrer"
            >
              freeCodeCamp forum
            </a>
            , among others)
          </li>
          <li>
            “How to start learning to code” advice (there are many communities
            already for this,{" "}
            <a
              href="https://www.freecodecamp.org/forum/"
              target="_blank"
              rel="noopener noreferrer"
            >
              freeCodeCamp
            </a>{" "}
            being the best example)
          </li>
          <li>Off-topic conversations</li>
          <li>
            Self-promotion: this group is meant to discuss the day to day topics
            that matter to junior developers and the project we’re working on
            together.
          </li>
          <li>Spam</li>
          <li>
            Initiating unsolicited private conversations: only message someone
            if you have been explicitly allowed or asked to do so.
          </li>
        </ul>
        <h2>Other guidelines to help keep the chat clutter-free:</h2>
        <ul>
          <li>
            Try to write your message into 1-2 long messages instead or a quick
            succession of short messages as it clutters the chat.
          </li>
          <li>
            If you’re having a conversation where only you and a small number of
            other people are participating (for instance if you’re
            troubleshooting something together, or discussing a very specific
            topic that no one else in the group is participating in), consider
            if it’s a general interest thing or if you can take it to direct
            messages.{" "}
          </li>
          <li>
            Before asking a question about the group or the project, make sure
            you’ve gone through the About section and project description on the
            website, the Readme, Contributing guide and Wiki of the project
            repository, and the Guidelines in the website, linked also on the
            Telegram group’s info section.
          </li>
          <li>
            Before asking a general question (“How do I use Figma?”), please use
            Google. There are tons and tons of tutorials on virtually any topic
            you want to learn. Leave the chat for when you’re actually stuck
            after trying on your own for a while.
          </li>
        </ul>
        <h2>Ban</h2>
        <p>
          If you don’t follow these guidelines or{" "}
          <a href="#code-of-conduct"> Code of Conduct</a> you can be banned with
          or without warning
        </p>
      </section>
      <section className="internal-link-target" id="code-of-conduct">
        <h1>Code of conduct</h1>
        <h2 id="our-pledge">Our Pledge</h2>
        <p>
          In the interest of fostering an open and welcoming environment, we as
          website maintainers, group administrators and members of Junior Devs
          pledge to make participation in our projects and our community a
          harassment-free experience for everyone, regardless of age, body size,
          disability, ethnicity, sex characteristics, gender identity and
          expression, level of experience, education, socio-economic status,
          nationality, personal appearance, race, religion, or sexual identity
          and orientation.
        </p>
        <h2 id="our-standards">Our Standards</h2>
        <p>
          Examples of behavior that contributes to creating a positive
          environment include:
        </p>
        <ul>
          <li>Using welcoming and inclusive language</li>
          <li>Being respectful of differing viewpoints and experiences</li>
          <li>Gracefully accepting constructive criticism</li>
          <li>Focusing on what is best for the community</li>
          <li>Showing empathy towards other community members</li>
        </ul>
        <p>Examples of unacceptable behavior by participants include:</p>
        <ul>
          <li>
            The use of sexualized language or imagery and unwelcome sexual
            attention or advances
          </li>
          <li>
            Trolling, insulting/derogatory comments, and personal or political
            attacks
          </li>
          <li>Public or private harassment</li>
          <li>
            Publishing others&rsquo; private information, such as a physical or
            electronic address, without explicit permission
          </li>
          <li>
            Other conduct which could reasonably be considered inappropriate in
            a professional setting
          </li>
        </ul>
        <h2 id="our-responsibilities">Our Responsibilities</h2>
        <p>
          Junior Devs website maintainers and group administrators are
          responsible for clarifying the standards of acceptable behavior and
          are expected to take appropriate and fair corrective action in
          response to any instances of unacceptable behavior.
        </p>
        <p>
          Junior Devs website maintainers and group administrators have the
          right and responsibility to remove, edit, or reject comments, commits,
          code, wiki edits, issues, and other contributions that are not aligned
          to this Code of Conduct, or to ban temporarily or permanently any
          contributor for other behaviors that they deem inappropriate,
          threatening, offensive, or harmful.
        </p>
        <h2 id="scope">Scope</h2>
        <p>
          This Code of Conduct applies within all project spaces, including but
          not limited to the Junior Devs Telegram group and the group&rsquo;s
          project(s)&rsquo; GitHub repository, and it also applies when an
          individual is representing the project or its community in public
          spaces. Examples of representing a project or community include using
          an official project e-mail address, posting via an official social
          media account, or acting as an appointed representative at an online
          or offline event. Representation of a project may be further defined
          and clarified by the Junior Devs website maintainers and group
          administrators.
        </p>
        <h2 id="enforcement">Enforcement</h2>
        <p>
          Instances of abusive, harassing, or otherwise unacceptable behavior
          may be reported by contacting the Junior Devs team at{" "}
          <a href="mailto:juniordevsteam@gmail.com">juniordevsteam@gmail.com</a>
          . All complaints will be reviewed and investigated and will result in
          a response that is deemed necessary and appropriate to the
          circumstances. The project team is obligated to maintain
          confidentiality with regard to the reporter of an incident. Further
          details of specific enforcement policies may be posted separately.
        </p>
        <p>
          Project maintainers who do not follow or enforce the Code of Conduct
          in good faith may face temporary or permanent repercussions as
          determined by other members of Junior Devs leadership.
        </p>
        <h2 id="attribution">Attribution</h2>
        <p>
          This Code of Conduct is adapted from the{" "}
          <a href="https://www.contributor-covenant.org">
            Contributor Covenant
          </a>
          , version 1.4, available at{" "}
          <a href="https://www.contributor-covenant.org/version/1/4/code-of-conduct">
            https://www.contributor-covenant.org/version/1/4/code-of-conduct.html
          </a>
        </p>
        <p>
          For answers to common questions about this code of conduct, see{" "}
          <a href="https://www.contributor-covenant.org/faq">
            https://www.contributor-covenant.org/faq
          </a>
        </p>
      </section>
      <section className="internal-link-target" id="acknowledgements">
        <h1>Acknowledgments</h1>
        <p>
          The illustrations on this website are from{" "}
          <a href="https://undraw.co">Undraw</a>
        </p>
      </section>
    </ContentWrapper>
  );
};

export default About;
